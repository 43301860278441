import * as React from "react";
import { 
    List, Datagrid, Pagination,
    Show, SimpleShowLayout, TextField, EmailField, DateField, FunctionField, BooleanField,
    Edit, SimpleForm, TextInput, required,
    TopToolbar, Toolbar, ExportButton, EditButton, DeleteWithConfirmButton, SaveButton, useRecordContext, useListContext
} from 'react-admin';

export const UserList = () => {
    const ListActions = () => (
        <TopToolbar>
            <ExportButton maxResults={2147483647} />
        </TopToolbar>
    );
    
    const ListPagination = () => {
        const { data } = useListContext();
        if (data && data.length > 0 && data[0].isPaged === false) return null;
        return <Pagination rowsPerPageOptions={[5, 10, 25, 50]} />;
    };

    const listFilters = [
        <TextInput label="Search" source="q" alwaysOn />
    ];

    return (
        <>
            <List actions={<ListActions />} filters={listFilters} pagination={<ListPagination />} perPage={25}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                    <EmailField label="Email" source="email" />
                    <FunctionField label="Name" render={record => record.firstName && record.lastName ? `${record.firstName} ${record.lastName}` : null} />
                    <TextField label="Role" source="role" />
                    <FunctionField label="SSO" render={record => `${record.ssoProvider}/${record.ssoType}`} />
                    <DateField label="Last Active" source="lastActiveAt" showTime locales="sv-SE" />
                    <BooleanField label="Deleted?" source="deleted" />
                </Datagrid>
            </List>
            <br />
        </>
    );
};

export const UserShow = () => {
    const ShowActions = () => {
        const record = useRecordContext();
        return (
            <TopToolbar>
                <EditButton />
                {record && <DeleteWithConfirmButton
                    confirmTitle="Delete User"
                    confirmContent={`Are you sure you want to delete User with KSafe ID ${record.id}? This action cannot be undone.`}
                />}
            </TopToolbar>
        );
    };

    return (
        <Show actions={<ShowActions />}>
            <SimpleShowLayout>
                <TextField label="ID" source="id" />
                <EmailField label="Email" source="email" />
                <TextField label="First Name" source="firstName" />
                <TextField label="Last Name" source="lastName" />
                <TextField label="Role" source="role" />
                <TextField label="Safety Score" source="safetyScore" />
                <TextField label="Device OS" source="deviceOs" />
                <TextField label="SSO ID" source="ssoId" />
                <TextField label="SSO Provider" source="ssoProvider" />
                <TextField label="SSO Type" source="ssoType" />
                <TextField label="Support ID" source="supportId" />
                <TextField label="Timezone" source="timezone" />
                <DateField source="createdAt" showTime locales="sv-SE" label="Created" />
                <DateField source="updatedAt" showTime locales="sv-SE" label="Updated" />
                <DateField source="lastActiveAt" showTime locales="sv-SE" label="Last Active" />
                <BooleanField source="deleted" label="Deleted?" />
            </SimpleShowLayout>
        </Show>
    );
};

export const UserEdit = () => {
    const EditToolbar = () => (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );

    return (
        <Edit>
            <SimpleForm toolbar={<EditToolbar />}>
                <TextInput label="ID" source="id" fullWidth={true} validate={[required()]} disabled />
                <TextInput label="First Name" source="firstName" fullWidth={true} />
                <TextInput label="Last Name" source="lastName" fullWidth={true} />
                <TextInput label="Email" source="email" fullWidth={true} />
                <TextInput label="Metamask Address" source="metamaskAddress" fullWidth={true} />
            </SimpleForm>
        </Edit>
    );
};
import configData from "../config.json";

const prodDomains = [
    'victorious-mud-0271cdd00.1.azurestaticapps.net',
    'portal.ksafe.com.au'
];

const stagingDomains = [
    'icy-glacier-0ba7d4100.1.azurestaticapps.net',
    'portal.staging.ksafe.com.au'
];

const domain = window.location.host;

let env = '';
if (prodDomains.includes(domain)) {
    env = 'Production';
} else if (stagingDomains.includes(domain)) {
    env = 'Staging';
} else {
    env = 'Local';
}

export const Version = configData['AppVersion'];
export const Environment = configData["Environments"].find(e => e.Name === env);
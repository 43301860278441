import * as React from "react";
import { 
    List, Datagrid, 
    Show, SimpleShowLayout,
    TextField, DateField, BooleanField, FunctionField, ReferenceField, FileField,
    Create, SimpleForm, FormDataConsumer, TextInput, ReferenceInput, SelectInput, AutocompleteInput, BooleanInput, FileInput, required,
    TopToolbar, CreateButton, ExportButton, DeleteWithConfirmButton, useRecordContext, useNotify, useRedirect
} from 'react-admin';
import { JsonField } from "react-admin-json-view";

import ImportGroupNotificationIcon from '@mui/icons-material/NotificationAdd';

export const NotificationList = () => {
    const ListActions = () => (
        <TopToolbar>
            <CreateButton />
            <CreateButton label="Send Group Notification" resource="notifications/group" icon={<ImportGroupNotificationIcon />} />
            <ExportButton maxResults={2147483647} />
        </TopToolbar>
    );

    return (
        <List actions={<ListActions/>} perPage={25}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField label="Type" source="type" />
                <FunctionField label="User" render={record =>`${record.firstName ?? ''} ${record.lastName ?? ''} <${record.email ?? ''}> - ${record.supportId}`} />
                <TextField label="Trip" source="tripUuid" />
                <BooleanField label="Read?" source="isRead" />
                <BooleanField label="Pushed?" source="isPushed" />
                <DateField label="Pushed" source="pushedAt" showTime locales="sv-SE" />
                <BooleanField label="Failed To Send?" source="failedToSend" />
                <TextField label="Push Count" source="pushCount" />
                <TextField label="Failure Count" source="failureCount" />
                <TextField label="Disabled Count" source="disabledCount" />
                <BooleanField label="Hidden?" source="isHidden" />
            </Datagrid>
        </List>
    );
};

export const NotificationShow = () => {
    const ShowActions = () => {
        const record = useRecordContext();
        return (
            <TopToolbar>
                {record && <DeleteWithConfirmButton
                    confirmTitle="Delete Transaction"
                    confirmContent={`Are you sure you want to delete Transaction with KSafe ID ${record.id}? This action cannot be undone.`}
                />}
            </TopToolbar>
        );
    };

    return (
        <Show actions={<ShowActions />}>
            <SimpleShowLayout>
                <TextField label="ID" source="id" />
                <TextField label="Type" source="type" />
                <ReferenceField label="User" source="userUuid" reference="users" link="show" emptyText="-">
                    <FunctionField render={record => `${record.firstName ?? ''} ${record.lastName ?? ''} <${record.email ?? ''}> - ${record.supportId}`} />
                </ReferenceField>
                {/*<ReferenceField label="From User" source="fromUserUuid" reference="users" link="show" emptyText="-">
                    <FunctionField render={record => `${record.firstName ?? ''} ${record.lastName ?? ''} <${record.email ?? ''}> - ${record.supportId}`} />
                </ReferenceField>*/}
                <ReferenceField label="Trip" source="tripUuid" reference="trips" emptyText="-" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <JsonField label="Params" source="paramsJson" addLabel={true} jsonString={true} reactJsonOptions={{ name: null, collapsed: false, enableClipboard: false, displayDataTypes: false }} />
                <BooleanField label="Read?" source="isRead" />
                <BooleanField label="Pushed?" source="isPushed" />
                <DateField label="Push Scheduled" source="pushScheduledAt" showTime locales="sv-SE" />
                <DateField label="Pushed" source="pushed" showTime locales="sv-SE" />
                <BooleanField label="Failed To Send?" source="failedToSend" />
                <BooleanField label="Push Disabled By User Preference?" source="pushDisabledByUserPreference" />
                <TextField label="Push Count" source="pushCount" />
                <TextField label="Failure Count" source="failureCount" />
                <TextField label="Disabled Count" source="disabledCount" />
                <BooleanField label="Hidden?" source="isHidden" />
                <DateField source="createdAt" showTime locales="sv-SE" label="Created" />
                <DateField source="updatedAt" showTime locales="sv-SE" label="Updated" />
            </SimpleShowLayout>
        </Show>
    );
};

export const NotificationCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
		notify(`Notification created`);
		redirect(`/notifications`);
	};

    return (
        <Create mutationOptions={{ onSuccess }}>
            <SimpleForm>
                <SelectInput label="Type" source="type" fullWidth={true} validate={[required()]} choices={[
                    { id: 'ALL_USERS_SYSTEM_MESSAGE', name: 'SYSTEM WIDE' },
                    { id: 'USER_SYSTEM_MESSAGE', name: 'USER' }
                ]} />
                <FormDataConsumer>
                    {({ formData }) => formData.type === 'ALL_USERS_SYSTEM_MESSAGE' ? (
                        <>
                            <TextInput label="Message" source="message" fullWidth={true} validate={[required()]} />
                            <TextInput label="URL" source="url" type="url" fullWidth={true} validate={[required()]} />
                            <TextInput label="Title" source="title" fullWidth={true} validate={[required()]}/>
                            <TextInput label="Category" source="category" fullWidth={true} validate={[required()]}/>
                        </>
                    ) : (formData.type === 'USER_SYSTEM_MESSAGE' ? (
                        <>
                            <ReferenceInput label="User" source="userId" reference="users" enableGetChoices={({ q }) => q !== undefined && q.length >= 1} perPage={2147483647} sort={{ field: 'lastName', order: 'ASC' }} fullWidth={true} validate={[required()]}>
                                <AutocompleteInput optionText={record => `${record.firstName ?? ''} ${record.lastName ?? ''} <${record.email ?? ''}> - ${record.supportId}`} fullWidth={true} validate={[required()]} />
                            </ReferenceInput>
                            <TextInput label="Message" source="message" fullWidth={true} validate={[required()]} />
                            <TextInput label="URL" source="url" type="url" fullWidth={true} />
                            <TextInput label="Title" source="title" fullWidth={true} validate={[required()]}/>
                            <TextInput label="Category" source="category" fullWidth={true} validate={[required()]}/>
                        </>
                    ) : null)}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
};

export const NotificationImportGroup = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {       
		notify(`Support IDs requested: ${data.numberRequested}, Deleted users: ${data.numberDeleted}, Valid users: ${data.numberValid}, Validate only: ${data.validate}`, { autoHideDuration: 8000 });
        if (data.validate === false)
		    redirect(`/notifications`);
	};
    
    return (
        <Create mutationOptions={{ onSuccess }} title="Send Group Notification">
            <SimpleForm>
                <TextInput label="Message" source="message" fullWidth={true} validate={[required()]} />
                <BooleanInput label="Validate?" source="validate" validate={[required()]} helperText="If validate is set to true, the file will be processed but notifications will NOT be sent." />
                <br />
                <FileInput label="User List" source="files" multiple={false} accept="text/csv" validate={[required()]}>
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </Create>
    );
};
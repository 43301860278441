import * as React from "react";
import { 
    List, Datagrid, 
    TextField, FunctionField,
    TopToolbar, ExportButton, Button, Confirm,
    useRecordContext, useUpdate, useRefresh
} from 'react-admin';

import MarkAsProcessedIcon from '@mui/icons-material/PriceCheck';

const styles = {
    cellRight: {
        textAlign: 'right'
    }
};

export const formatCurrencyValue = (cashCents) => {
    if (cashCents === null)
        return '';
    
    return new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        currencyDisplay: 'narrowSymbol'
    }).format(cashCents / 100.0);
}

const MarkAsProcessedButton = () => {
    const record = useRecordContext();
    const [open, setOpen] = React.useState(false);
    const [update, { isLoading, error }] = useUpdate();
    const refresh = useRefresh();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        update('update-transaction-status', { id: record.id, data: { status: 'PROCESSED' }, previousData: record }, { mutationMode: 'pessimistic' });
        setOpen(false);
        refresh();
    };
    if (error) { return <p>ERROR</p>; }

    return (
        <React.Fragment>
            <Button label="Mark As Processed" children={<MarkAsProcessedIcon />} disabled={isLoading || record.status === 'PROCESSED'} onClick={handleClick} />
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={`Cash Withdrawal - ${record.id}`}
                content={`Are you sure you want to mark this withdrawal as processed?`}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </React.Fragment>
    );
};

export const WithdrawalsList = () => {
    const ListActions = () => (
        <TopToolbar>
            <ExportButton maxResults={2147483647} />
        </TopToolbar>
    );

    return (
        <List actions={<ListActions/>} perPage={25}>
            <Datagrid rowClick={null} bulkActionButtons={false} sx={{
                '& .column-points': styles.cellRight
            }}>
                <TextField label="ID" source="id" />
                <FunctionField label="User" render={record =>`${record.firstName ?? ''} ${record.lastName ?? ''} <${record.email ?? ''}> - ${record.supportId}`} />
                <FunctionField label="Cash" source="cashCents" render={record => `${record.type === 'CASH_WITHDRAWAL' ? '-' : ''}${formatCurrencyValue(record.cashCents)}`}  />
                <TextField label="Status" source="status" />
                <MarkAsProcessedButton />
            </Datagrid>
        </List>
    );
};
import * as React from "react";
import { Card, CardContent, CardHeader } from '@mui/material';

const Dashboard = () => (
    <Card>
        <CardHeader title="Welcome to the KSafe Admin Portal" />
        <CardContent>Select from the menu to manage users, trips, transactions, etc.</CardContent>
    </Card>
);

export default Dashboard;
import {
    Create,
     FileField, FileInput,
    required,
    SimpleForm,
    useNotify,
} from "react-admin";
import * as React from "react";

export const UpdateIntroductory = () => {
    const notify = useNotify();

    const onSuccess = () => {
        notify(`Video uploaded`);
    };

    return (
        <Create mutationOptions={{ onSuccess }}>
            <SimpleForm>
                <FileInput label="Video" source="video" accept="video/*" multiple={false} validate={[required()]}>
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </Create>
    );
};
import * as React from "react";
import { AppBar, Layout, Login } from 'react-admin';
import Typography from '@mui/material/Typography';

export const KSafeLogin = () => (
    <Login 
        sx={{
        "& .RaLogin-icon": {
            backgroundImage: "url('kSafe-logo.png')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "#fff",
            backgroundSize: "contain",
            width: 100,
            height: 100,
            borderRadius: "initial"
        },
        "& .RaLogin-icon svg": {
            display: "none"
        }
    }}/>
);

const KSafeAppBar = (props) => (
    <AppBar
        sx={{
            "& .RaAppBar-toolbar": {
                backgroundColor: "transparent",
                backgroundImage: "linear-gradient(180deg, #133074 0%, #0A2157 100%)"
            },
            "#react-admin-title": {
                flex: 1,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                color: "#fff"
            }
        }}
        {...props}
    >
        <img src="kSafe-logo.png" alt="KSafe" style={{height:32,paddingRight:12}} />&nbsp;
        <Typography
            variant="h6"
            color="inherit"
            id="react-admin-title"
        />
    </AppBar>
);

export const KSafeLayout = (props) => <Layout {...props} appBar={KSafeAppBar} sx={{}} />;
import * as yup from "yup";
import {useCallback, useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import dataProvider from "../providers/dataProvider";
import {Alert, Card, Snackbar, Stack, TextField, Typography, Switch, Button, Box} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import * as React from "react";

const sysNotificationSchema = yup.object({
    headline: yup.string().required('Required!').max(128, 'Max length is 128 characters!'),
    body: yup.string().required('Required!').max(1024, 'Max length is 1024 characters!'),
    link: yup.string().required('Required!').max(1024, 'Max length is 1024 characters!'),
}).required();

export const NotificationPopUp = () => {
    const [systemNotification, setSystemNotification] = useState(null)
    const [submitStatus, setSubmitStatus] = React.useState(null);

    const { control, register, handleSubmit, formState:{ errors }, reset } = useForm({
        defaultValues: systemNotification,
        resolver: yupResolver(sysNotificationSchema)
    });
    const fetchSystemNotification = useCallback(async () => {
        const { data } = await dataProvider.getOne('system-notification')
        setSystemNotification(data)
        reset(data);
    }, [reset])

    useEffect(() => {
        fetchSystemNotification()
    }, [fetchSystemNotification])

    const onSubmit = useCallback(async (data) => {
        try {
            await dataProvider.create('system-notification', {data})
            setSubmitStatus("success")
        }catch (e) {
            setSubmitStatus("error")
        }
    }, []);


    return (
        <>
            <Card sx={{paddingTop: "16px", mt: 2}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack sx={{padding: "30px 20px", width: "100%"}} spacing={2}>
                        <Stack direction={"row"} alignItems={'center'} spacing={2}>
                            <Typography variant="h6" sx={{minWidth: "120px"}} pr={3} align={"right"}>
                                Headline
                            </Typography>
                            <Stack sx={{minWidth: "40%"}}>
                                <TextField error={!!errors?.headline} {...register("headline")}/>
                                {errors?.headline && <Typography sx={{color: 'red'}}>{errors.headline?.message}</Typography>}
                            </Stack>
                        </Stack>

                        <Stack direction={"row"} alignItems={'center'} spacing={2}>
                            <Typography variant="h6" sx={{minWidth: "120px"}} pr={3} align={"right"}>
                                Body
                            </Typography>
                            <Stack sx={{minWidth: "40%"}}>
                                <TextField {...register("body")} error={!!errors?.body} multiline minRows={4} maxRows={8}/>
                                {errors?.body && <Typography sx={{color: 'red'}}>{errors.body?.message}</Typography>}
                            </Stack>
                        </Stack>
                        <Stack direction={"row"} alignItems={'center'} spacing={2}>
                            <Typography variant="h6" sx={{minWidth: "120px"}} pr={3} align={"right"}>
                                Link
                            </Typography>
                            <Stack sx={{minWidth: "40%"}}>
                                <TextField {...register("link")} error={!!errors?.link} sx={{minWidth: "40%"}} />
                                {errors?.link && <Typography sx={{color: 'red'}}>{errors.link?.message}</Typography>}
                            </Stack>

                        </Stack>
                        <Stack direction={"row"} alignItems={'center'} spacing={2}>
                            <Typography variant="h6" sx={{minWidth: "120px"}} pr={3} align={"right"}>
                                Active
                            </Typography>
                            <Controller
                                name="isActive"
                                control={control}
                                render={({ field}) =>
                                    <Switch {...field} checked={field.value} onChange={field.onChange} />}
                            />
                        </Stack>
                    </Stack>
                    <Box px={3} py={1.5} sx={{bgcolor: "#f5f5f5"}}>
                        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                            <SaveIcon/>&nbsp;Save
                        </Button>
                    </Box>
                </form>
            </Card>
            <Snackbar
                open={!!submitStatus}
                autoHideDuration={6000}
                onClose={() => setSubmitStatus(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={() => setSubmitStatus(null)} severity={submitStatus ?? 'success'} sx={{ width: '100%' }}>
                    {submitStatus && (submitStatus === 'success' ? "Update successfully!" : "An occur errors, try again later!")}
                </Alert>
            </Snackbar>
        </>
    )
}

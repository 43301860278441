import {
    Datagrid, DateField,
    Edit, EditButton,
    FileField, FileInput, List, maxLength,
    required, SaveButton, Show,
    SimpleForm, SimpleShowLayout, TextField, TextInput, Toolbar, TopToolbar,
} from "react-admin";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import * as React from "react";

export const LeaderboardList = () => {
    return (
        <List perPage={25}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField label="Header" source="header" />
                <TextField label="Body" source="body" />
                <TextField label="Column 1" source="column1" />
                <TextField label="Column 2" source="column2" />
                <TextField label="Column 3" source="column3" />
                <TextField label="Title" source="bannerTitle" />
                <TextField label="Description" source="bannerDescription" />
                <TextField label="Link" source="bannerLink" />
            </Datagrid>
        </List>
    );
};


export const LeaderboardShow = () => {
    const ShowActions = () => {
        return (
            <TopToolbar>
                <EditButton />
            </TopToolbar>
        );
    };

    return (
        <Show actions={<ShowActions />}>
            <SimpleShowLayout>
                <TextField label="Header" source="header" />
                <TextField label="Body" source="body" />
                <TextField label="Column 1" source="column1" />
                <TextField label="Column 2" source="column2" />
                <TextField label="Column 3" source="column3" />
                <TextField label="Title" source="bannerTitle" />
                <TextField label="Description" source="bannerDescription" />
                <TextField label="Link" source="bannerLink" />
                <DateField source="createdAt" showTime locales="sv-SE" label="Created" />
                <DateField source="updatedAt" showTime locales="sv-SE" label="Updated" />
            </SimpleShowLayout>
        </Show>
    );
};

export const ImportLeaderboard = () => {
    const EditToolbar = () => (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );

    return (
        <Edit>
            <SimpleForm toolbar={<EditToolbar />}>
                <Typography fontWeight={'bold'}>Leaderboard</Typography>
                <TextInput label="Header" source="header" fullWidth={true} validate={[required(), maxLength(64)]} />
                <TextInput label="Body" source="body" multiline={true} fullWidth={true} validate={[required(), maxLength(1024)]} />
                <TextInput label="Column 1" source="column1" multiline={true} fullWidth={true} validate={[required(), maxLength(1024)]} />
                <TextInput label="Column 2" source="column2" multiline={true} fullWidth={true} validate={[required(), maxLength(1024)]} />
                <TextInput label="Column 3" source="column3" multiline={true} fullWidth={true} validate={[required(), maxLength(1024)]} />
                <Divider/>
                <Typography fontWeight={'bold'}>Banner</Typography>
                <TextInput label="Title" source="bannerTitle" fullWidth={true} validate={[required(), maxLength(64)]} />
                <TextInput label="Description" source="bannerDescription" multiline={true} fullWidth={true} validate={[required(), maxLength(1024)]} />
                <TextInput label="Link" source="bannerLink" fullWidth={true} type="url" validate={[maxLength(1024)]} />
                <FileInput label="Import file" source="file" accept="text/csv" multiple={false}>
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </Edit>
    );
}
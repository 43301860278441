import * as React from "react";
import { 
    List, Datagrid, 
    Show, SimpleShowLayout,
    TextField, DateField, FunctionField, ReferenceField,
    Create, Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, AutocompleteInput, NumberInput, required,
    TopToolbar, Toolbar, CreateButton, ExportButton, EditButton, DeleteWithConfirmButton, SaveButton, useRecordContext, useNotify, useRedirect
} from 'react-admin';

import AddRemoveCashIcon from '@mui/icons-material/ExposureOutlined';

const styles = {
    cellRight: {
        textAlign: 'right'
    }
};

export const formatCurrencyValue = (cashCents) => {
    if (cashCents === null)
        return '';
    
    return new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        currencyDisplay: 'narrowSymbol'
    }).format(cashCents / 100.0);
}

export const TransactionList = () => {
    const ListActions = () => (
        <TopToolbar>
            <CreateButton label="Add/Remove Cash (cents)" icon={<AddRemoveCashIcon />} />
            <ExportButton maxResults={10000} />
        </TopToolbar>
    );

    return (
        <List actions={<ListActions/>} perPage={25}>
            <Datagrid rowClick="show" bulkActionButtons={false} sx={{
                '& .column-points': styles.cellRight
            }}>
                <TextField label="ID" source="id" />
                <FunctionField label="User" render={record =>`${record.firstName ?? ''} ${record.lastName ?? ''} <${record.email ?? ''}> - ${record.supportId}`} />
                <FunctionField label="Cash" source="cashCents" render={record => `${record.type === 'ADD' ? '+' : (record.type === 'SUBTRACT' ? '-' : '')}${formatCurrencyValue(record.cashCents)}`}  />
                <TextField label="Status" source="status" />
            </Datagrid>
        </List>
    );
};

export const TransactionShow = () => {
    const ShowActions = () => {
        const record = useRecordContext();
        return (
            <TopToolbar>
                <EditButton />
                {record && <DeleteWithConfirmButton
                    confirmTitle="Delete Transaction"
                    confirmContent={`Are you sure you want to delete Transaction with KSafe ID ${record.id}? This action cannot be undone.`}
                />}
            </TopToolbar>
        );
    };

    return (
        <Show actions={<ShowActions />}>
            <SimpleShowLayout>
                <TextField label="ID" source="id" />
                <ReferenceField label="User" source="userId" reference="users" link="show">
                    <FunctionField render={record => `${record.firstName ?? ''} ${record.lastName ?? ''} <${record.email ?? ''}> - ${record.supportId}`} />
                </ReferenceField>
                <FunctionField label="Cash" render={record => `${record.type === 'ADD' ? '+' : (record.type === 'SUBTRACT' ? '-' : '')}${formatCurrencyValue(record.cashCents)}`} />
                <TextField label="Status" source="status" />
                <TextField label="Metamask Address" source="metamaskAddress" />
                <DateField source="createdAt" showTime locales="sv-SE" label="Created" />
                <DateField source="updatedAt" showTime locales="sv-SE" label="Updated" />
            </SimpleShowLayout>
        </Show>
    );
};

export const TransactionEdit = () => {
    const EditToolbar = () => (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );

    return (
        <Edit>
            <SimpleForm toolbar={<EditToolbar />}>
                <TextInput label="ID" source="id" fullWidth={true} validate={[required()]} disabled />
                <SelectInput label="Status" source="status" fullWidth={true} validate={[required()]} choices={[
                    { id: 'REJECTED', name: 'REJECTED' },
                    { id: 'PROCESSED', name: 'PROCESSED' }
                ]} />
                <NumberInput label="Cash (cents)" source="cashCents" min={0} step={1} fullWidth={true} />
            </SimpleForm>
        </Edit>
    );
};


export const TransactionCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
		notify(`Points added/subtracted for transaction`);
		redirect(`/transactions`);
	};
    
    return (
        <Create mutationOptions={{ onSuccess }}>
            <SimpleForm>
                <ReferenceInput label="User" source="userId" reference="users" enableGetChoices={({ q }) => q !== undefined && q.length >= 1} perPage={2147483647} sort={{ field: 'lastName', order: 'ASC' }} fullWidth={true} validate={[required()]}>
                    <AutocompleteInput optionText={record => `${record.firstName ?? ''} ${record.lastName ?? ''} <${record.email ?? ''}> - ${record.supportId}`} fullWidth={true} />
                </ReferenceInput>
                <SelectInput label="Type" source="type" fullWidth={true} validate={[required()]} choices={[
                    { id: 'ADD', name: 'ADD' },
                    { id: 'SUBTRACT', name: 'SUBTRACT' }
                ]} />
                <NumberInput label="Cash (cents)" source="cashCents" min={0} step={1} fullWidth={true} />
            </SimpleForm>
        </Create>
    );
};
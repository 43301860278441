import {
    Create,
    ImageField,
    ImageInput,
    SimpleForm,
    useNotify,
} from "react-admin";
import { Typography } from "@mui/material";
import * as React from "react";
import { Environment } from "../providers/configProvider";

const WinUrl = `${Environment.BlobPath}/banners/win-banner.jpg`
const ShopUrl = `${Environment.BlobPath}/banners/shop-banner.jpg`
export const UpdateRewardBanner = () => {
    const notify = useNotify();

    const onSuccess = () => {
        notify(`Images uploaded`);
    };

    return (
        <Create mutationOptions={{ onSuccess }}>
            <SimpleForm>
                <Typography>Win banner</Typography>
                <img alt={'win-banner'} src={WinUrl} style={{ maxWidth: 300, maxHeight: 300, objectFit: 'contain' }} />
                <ImageInput label="Updated Image" source="winImage" accept="image/*" multiple={false}>
                    <ImageField source="src" title="title" sx={{ '& img': { maxWidth: 300, maxHeight: 300, objectFit: 'contain' } }} />
                </ImageInput>
                <Typography>Shop banner</Typography>
                <img alt={'shop-banner'} src={ShopUrl} style={{ maxWidth: 300, maxHeight: 300, objectFit: 'contain' }} />
                <ImageInput label="Updated Image" source="shopImage" accept="image/*" multiple={false}>
                    <ImageField source="src" title="title" sx={{ '& img': { maxWidth: 300, maxHeight: 300, objectFit: 'contain' } }} />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};
import {
    BooleanField,
    BooleanInput,
    Create,
    CreateButton,
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    ImageField,
    ImageInput,
    List,
    maxLength,
    NumberInput,
    required,
    SaveButton,
    Show,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
    useNotify,
    useRecordContext,
    SimpleShowLayout,
    useRedirect,
} from "react-admin";
import * as React from "react";

export const NewsList = () => {
    const ListActions = () => (
        <TopToolbar>
            <CreateButton />
        </TopToolbar>
    );

    return (
        <List actions={<ListActions/>} perPage={25}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField label="Name" source="name" />
                <TextField label="Description" source="description" />
                <TextField label="Link" source="link" />
                <ImageField label="Attachment" source="attachmentUrl" title="name" sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }} />
                <BooleanField label="Featured?" source="isFeatured" />
                <BooleanField label="Active?" source="isActive" />
                <TextField label="Min read" source="readMin" />
                <TextField label="Priority" source="priority" />
            </Datagrid>
        </List>
    );
};

export const NewsShow = () => {
    const ShowActions = () => {
        const record = useRecordContext();
        return (
            <TopToolbar>
                <EditButton />
                {record && <DeleteWithConfirmButton
                    confirmTitle="Delete Banner"
                    confirmContent={`Are you sure you want to delete Banner with KSafe ID ${record.id}? This action cannot be undone.`}
                />}
            </TopToolbar>
        );
    };

    return (
        <Show actions={<ShowActions />}>
            <SimpleShowLayout>
                <TextField label="Name" source="name" />
                <TextField label="Description" source="description" />
                <TextField label="Link" source="link" />
                <ImageField label="Attachment" source="attachmentUrl" title="name" sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }} />
                <BooleanField label="Featured?" source="isFeatured" />
                <BooleanField label="Active?" source="isActive" />
                <TextField label="Min read" source="readMin" />
                <TextField label="Priority" source="priority" />
                <DateField source="createdAt" showTime locales="sv-SE" label="Created" />
                <DateField source="updatedAt" showTime locales="sv-SE" label="Updated" />
            </SimpleShowLayout>
        </Show>
    );
};

export const NewsCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`News created`);
        redirect(`/news`);
    };

    return (
        <Create mutationOptions={{ onSuccess }}>
            <SimpleForm>
                <TextInput label="Name" source="name" fullWidth={true} validate={[required(), maxLength(64)]} />
                <TextInput label="Description" source="description" multiline={true} fullWidth={true} validate={[required(), maxLength(1024)]} />
                <TextInput label="Link" source="link" fullWidth={true} type="url" validate={[maxLength(1024)]} />
                <BooleanInput label="Featured?" source="isFeatured" fullWidth={true} validate={[required()]} />
                <BooleanInput label="Active?" source="isActive" fullWidth={true} validate={[required()]} />
                <NumberInput label="Min read" source="readMin" min={0} step={1} fullWidth={true} validate={[required()]} />
                <NumberInput label="Priority" source="priority" min={0} step={1} fullWidth={true} validate={[required()]} />
                <ImageInput label="Attachment" source="attachment" accept="image/*" multiple={false} validate={[required()]}>
                    <ImageField source="src" title="title" sx={{ '& img': { maxWidth: 300, maxHeight: 300, objectFit: 'contain' } }} />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};

export const NewsEdit = () => {
    const EditToolbar = () => (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );

    return (
        <Edit>
            <SimpleForm toolbar={<EditToolbar />}>
                <TextInput label="ID" source="id" fullWidth={true} validate={[required()]} disabled />
                <TextInput label="Name" source="name" fullWidth={true} validate={[required(), maxLength(64)]} />
                <TextInput label="Description" source="description" multiline={true} fullWidth={true} validate={[required(), maxLength(1024)]} />
                <TextInput label="Link" source="link" fullWidth={true} type="url" validate={[maxLength(1024)]} />
                <BooleanInput label="Featured?" source="isFeatured" fullWidth={true} validate={[required()]} />
                <BooleanInput label="Active?" source="isActive" fullWidth={true} validate={[required()]} />
                <NumberInput label="Min read" source="readMin" min={0} step={1} fullWidth={true} validate={[required()]} />
                <NumberInput label="Priority" source="priority" min={0} step={1} fullWidth={true} validate={[required()]} />
                <TextInput label="Image URL" source="attachmentUrl" fullWidth={true} type="url" />
                <ImageField label="Current Image" source="attachmentUrl" title="name" sx={{ '& img': { maxWidth: 300, maxHeight: 300, objectFit: 'contain' } }} />
                <ImageInput label="Attachment" source="attachment" accept="image/*" multiple={false}>
                    <ImageField source="src" title="title" sx={{ '& img': { maxWidth: 300, maxHeight: 300, objectFit: 'contain' } }} />
                </ImageInput>
            </SimpleForm>
        </Edit>
    );
}
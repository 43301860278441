import { Card, Stack, TextField, Typography, Button, Box } from "@mui/material";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import dataProvider from "../providers/dataProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useNotify, Loading, Confirm } from "react-admin";

export const UnlimitedBanking = () => {
  const [session, setSession] = useState();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);

  const getBankingSession = useCallback(async () => {
    const { data } = await dataProvider.getOne("banking-session");
    setSession(data);
  }, []);

  useEffect(() => {
    getBankingSession().catch(console.error);
  }, [getBankingSession]);

  const onUpdate = useCallback(async () => {
    setLoading(true);
    try {
      await dataProvider.create("banking-session", { data: session });
      await getBankingSession();
      notify("Update Successful");
    } catch (e) {
      notify(`Failed to save banking session: ${e.message}`);
    } finally {
      setLoading(false);
    }

  }, [getBankingSession, notify, session]);

  const onDelete = useCallback(async () => {
    setLoading(true);
    setIsOpenConfirmDelete(false);
    try {
      await dataProvider.delete("banking-session", { data: session });
      await getBankingSession();
    } finally {
      setLoading(false);
    }
    notify("Delete Successful");
  }, [notify, session, getBankingSession]);

  return (
    <Card sx={{ paddingTop: "16px", mt: 2, position: "relative" }}>
      <Stack sx={{ padding: "30px 20px", width: "100%" }} spacing={2}>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Typography
            variant="h6"
            sx={{ minWidth: "300px" }}
            pr={3}
            align={"right"}
          >
            Advert Count
          </Typography>
          <Stack sx={{ minWidth: "40%" }}>
            <TextField
              type="number"
              value={session?.advertCount || ""}
              onChange={(e) => {
                setSession({ ...session, advertCount: e.target.value });
              }}
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Typography
            variant="h6"
            sx={{ minWidth: "300px" }}
            pr={3}
            align={"right"}
          >
            Show Banner After Seconds
          </Typography>
          <Stack sx={{ minWidth: "40%" }}>
            <TextField
              type="number"
              value={session?.showBannerAfterSeconds || ""}
              onChange={(e) => {
                setSession({
                  ...session,
                  showBannerAfterSeconds: e.target.value,
                });
              }}
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Typography
            variant="h6"
            sx={{ minWidth: "300px" }}
            pr={3}
            align={"right"}
          >
            Show Banner Before Seconds
          </Typography>
          <Stack sx={{ minWidth: "40%" }}>
            <TextField
              type="number"
              value={session?.showBannerBeforeSeconds || ""}
              onChange={(e) => {
                setSession({
                  ...session,
                  showBannerBeforeSeconds: e.target.value,
                });
              }}
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Typography
            variant="h6"
            sx={{ minWidth: "300px" }}
            pr={3}
            align={"right"}
          >
            Start at
          </Typography>
          <Stack sx={{ minWidth: "40%" }}>
            <DateTimePicker
              maxDateTime={session?.endAt ? dayjs(session?.endAt) : null}
              value={session?.startAt ? dayjs(session?.startAt) : null}
              sx={{ minWidth: "40%" }}
              onChange={(v) => {
                setSession({ ...session, startAt: v });
              }}
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Typography
            variant="h6"
            sx={{ minWidth: "300px" }}
            pr={3}
            align={"right"}
          >
            End at
          </Typography>
          <Stack sx={{ minWidth: "40%" }}>
            <DateTimePicker
              minDateTime={session?.startAt ? dayjs(session?.startAt) : null}
              value={session?.endAt ? dayjs(session?.endAt) : null}
              sx={{ minWidth: "40%" }}
              onChange={(v) => {
                setSession({ ...session, endAt: v });
              }}
            />
          </Stack>
        </Stack>
      </Stack>
      <Box display="flex">
        <Box px={3} py={1.5}>
          <Button variant="contained" onClick={onUpdate}>
            <SaveIcon />
            &nbsp;Save
          </Button>
        </Box>
        <Box px={3} py={1.5}>
          <Button
            variant="contained"
            color="error"
            onClick={() => setIsOpenConfirmDelete(true)}
          >
            <SaveIcon />
            &nbsp;Delete
          </Button>
        </Box>
      </Box>
      {loading && (
        <Box
          position={"absolute"}
          top={0}
          left={0}
          width={"100%"}
          height={"100%"}
          backgroundColor="rgba(255,255,255, 0.4)"
          style={{ backdropFilter: "blur(7px)" }}
        >
          <Loading />
        </Box>
      )}
      <Confirm
        isOpen={isOpenConfirmDelete}
        loading={loading}
        title={`Delete`}
        content="Are you sure you want to delete this item?"
        onConfirm={onDelete}
        onClose={() => setIsOpenConfirmDelete(false)}
      />
    </Card>
  );
};
